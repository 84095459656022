import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafePipe } from '../../pipes/safe.pipe';
import { TimeAgoPipe } from '../../pipes/time-ago.pipe';



@NgModule({
  declarations: [SafePipe, TimeAgoPipe],
  imports: [
    CommonModule,
  ],
  exports: [SafePipe, TimeAgoPipe]
})
export class SharedModule { }
